/* .content p {
    color: white;
} */

.content  {
    margin-top: 10px;
    display: grid;
    width: 100vw;
    justify-content: center;
    /* height: 100vh; */
}

.content p {
    margin-bottom: 10px;
}

.content img {
    margin-bottom: 10px;
    margin-top: 10px;
    width: 50vw;
    height: 50vh;
}

@media (max-width: 1700px) {
    .content img {
        width: 100%;
        height: 100%;
    }
}